import { Dispatch, SetStateAction, createContext } from "react";
import { User, Location } from "../../generated/graphql";

export type AuthContextType = {
  permissions?: string[];
  currentUser?: User;
  userADGroups?: string[];
  userLocations?: Location[];
  isGlobalAdmin?: boolean;
  accessToken: string | undefined;
  refreshToken: string | undefined;
  authLoaded?: boolean;
};

const authContextData: AuthContextType = {
  permissions: [],
  currentUser: undefined,
  userADGroups: [],
  userLocations: [],
  isGlobalAdmin: false,
  accessToken: undefined,
  refreshToken: undefined,
  authLoaded: false
};

const AuthContext = createContext<{
  authContextData: AuthContextType | undefined;
  setAuthContextData?:
    | Dispatch<SetStateAction<AuthContextType | undefined>>
    | undefined;
  getLoggedInUser?: (email: string) => void;
  resetAuthContext?: (email: string) => void;
}>({ authContextData });
export default AuthContext;
