import { useContext, useEffect, useState } from "react";
import { AsyncSelect } from "../../async-select/AsyncSelect";
import { AsyncSelectData } from "../../async-select/AsyncSelectData";
import { useTranslation } from "react-i18next";
import AuthContext from "src/app/contexts/auth-context";
import { useGetCustomerByIdLazyQuery, useGetCustomersByCompanyIdLazyQuery } from "src/generated/graphql";

interface FormikFormSelectProps {
  fieldName: string;
  labelName?: string;
  intialId?: number;
  formik;
  type?: string;
  showLabel?: boolean;
}

const FormikCustomerFormSelect = (props: FormikFormSelectProps) => {

  console.log("FormikCustomerFormSelect");
  const customerService = useContext(AuthContext);

  const { t } = useTranslation();

  const [ getCustomerAccountCustomers, { data, loading, error, fetchMore }] = useGetCustomersByCompanyIdLazyQuery();

  const [getCustomerById,{data:customerById}] = useGetCustomerByIdLazyQuery();

  const [searchTerm, setSearchTerm] = useState('');


  const formikSetFieldValue = props.formik.setFieldValue;
  const [initialCustomer, setInitialCustomer] = useState<AsyncSelectData>();

  useEffect(()=>{
    if(props.intialId){
      getCustomerById({
        variables:{
          customerId:props.intialId
        }
      })
    }
  },[getCustomerById,props.intialId])


  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm) {
        getCustomerAccountCustomers({ variables: { 
          companyId: Number(customerService?.authContextData?.currentUser?.location?.companyId),
          search: searchTerm
         } });
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, getCustomerAccountCustomers, customerService?.authContextData?.currentUser?.location?.companyId]);

  useEffect(() => {

    if (customerById?.customerById) {
      console.log(customerById?.customerById);
      let customer = customerById?.customerById;

      if (customer) {
        let customerData = new AsyncSelectData({
          title: customer?.customerName,
          value: customer?.id,
        });
        setInitialCustomer(customerData);
        setTimeout(() => {
          formikSetFieldValue(props?.fieldName, customer?.id ?? 0);
        }, 200);
      } else {
        setInitialCustomer(undefined);
        formikSetFieldValue(props?.fieldName, 0);
      }
    }
  }, [
    setInitialCustomer,
    customerById,
    formikSetFieldValue,
    props?.fieldName,
    props?.intialId
  ]);

  const selectedCustomerChanged = (data: AsyncSelectData) => {
    formikSetFieldValue(props?.fieldName, data?.value);
  };
  const handleInputChange = (inputValue: any) => {
    setSearchTerm(inputValue?.target?.value);
  };

  return (
    <>
      <div className={`mt-3`}>
        <AsyncSelect
          label={props.showLabel ? t("modules.formik.customer") : ""}
          onItemSelected={selectedCustomerChanged}
          onOpen={() => { console.log('') }}
          defaultSelectOption={initialCustomer}
          key={props?.fieldName}       
          inputValueChanged={handleInputChange}
          data={data?.customers?.nodes?.map(
            (i) =>
              new AsyncSelectData({
                title: `${i.customerName} - ${i.customerCode}`,
                value: i.id,
            })
          )}
          loadingData={loading}
          hasError={Boolean(
            props.formik.errors?.[props?.fieldName] &&
            props.formik.touched?.[props?.fieldName]
          )}
          className={`w-full`}
        />
      </div>
    </>
  );
};

export { FormikCustomerFormSelect };
