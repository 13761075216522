import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext, { AuthContextType } from "src/app/contexts/auth-context";
import { useAuth } from "src/app/features/auth/hooks/useAuth";
import { getAuthClaimsEntity } from "src/app/utils";
import { User, useGetUserByEmailLazyQuery } from "src/generated/graphql";

export const AuthContextProvider = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  const isAuthRoute = location.pathname === "/auth";
  const navigate = useNavigate();
  const [authContextData, setAuthContextData] = useState<AuthContextType>();
  const { refreshToken, accessToken } = authContextData ?? {};

  const [
    getUserByEmail,
    { data: userData, loading: userLoading, error: userFetchError },
  ] = useGetUserByEmailLazyQuery({
    fetchPolicy: "network-only"
  });

  const getLoggedInUser = useCallback(
    (email?) => {
      let _email:string = email;
      if (!_email) {
        const accessToken = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refresh_token");

        if (accessToken && refreshToken) {
          _email = getAuthClaimsEntity(accessToken)?.email as string??"";
        }else if(!authContextData?.authLoaded){
          setTimeout(() => {
            setAuthContextData?.(_auth=>{
             return  {
              ...(authContextData ?? {}),
              authLoaded: true
            } as AuthContextType;
            });
          }, 300);
        }
      }
      getUserByEmail({
        variables: {
          email: _email,
        },
      });
    },
    [getUserByEmail, authContextData]
  );

  const resetAuthContext = useCallback((email:string) => {
    setAuthContextData(_data => ({ ..._data } as AuthContextType))
    getLoggedInUser(email);
  }, [setAuthContextData,getLoggedInUser]);

  const authValue = useMemo(
    () => ({ authContextData, setAuthContextData, getLoggedInUser, resetAuthContext }),
    [authContextData, setAuthContextData, getLoggedInUser, resetAuthContext]
  );

  const isTokenExpired = useCallback((token) => {
    console.log("Checking if token is expired: ", token);
    const decodedToken = getAuthClaimsEntity(token);
    let expired = (decodedToken?.exp ?? 0) * 1000 < (Date.now() / 1000);
    return expired;
  }, []);

  useEffect(() => {
    getLoggedInUser();
  }, [getLoggedInUser]);

  useEffect(() => {
    const interval = setInterval(() => {
      const accessToken = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refresh_token");
      if (refreshToken && accessToken) {
        if(isTokenExpired(authContextData?.accessToken)){
          console.log("Refreshing token");
          auth
            .refreshAccessToken(refreshToken)
            .then((response) => {
              if(response?.access_token && response?.refresh_token){
                localStorage.setItem("token", response?.access_token);
                localStorage.setItem("refresh_token", response?.refresh_token);
                setAuthContextData({
                  ...(authContextData ?? {}),
                  accessToken: response?.access_token,
                  refreshToken: response?.refresh_token,
                });
              }else{
                //Unable to refresh Token, likely due to refresh token expiration
                auth.logout(); // Logout if refresh token is expired
                navigate("login");
              }
            });
        }
      } 
    }, 15 * 60 * 1000); // Check token every 5 minutes

    return () => clearInterval(interval);
  }, [setAuthContextData, auth.refreshAccessToken, auth.logout, auth, authContextData, isTokenExpired, navigate]);


  useEffect(() => {
    const _data = {
      ...(authContextData ?? {}),
      currentUser: userData?.userByEmail as User
    } as AuthContextType

    if (
      !userLoading &&
      userData?.userByEmail?.id &&
      JSON.stringify(_data) != JSON.stringify(authContextData)
    ) {
      localStorage.setItem("currentUser", JSON.stringify(userData.userByEmail));
      setAuthContextData?.(_authContextData => {
        return _data;
      });
      setTimeout(() => {
        if (isAuthRoute) navigate("/landing");
      }, 300);
    }

    if (userFetchError?.graphQLErrors?.length ?? 0 > 0) {
      //setAuthContextData({} as AuthContextType);
      auth.logout();

      navigate("/login");
    }
  }, [userLoading, userData?.userByEmail, userFetchError?.graphQLErrors, navigate, isAuthRoute, auth, authContextData]);

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};
