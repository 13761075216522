// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { client } from "../graphql/bootstrap";
import { gql } from "@apollo/client";

const firebaseConfig = {
  apiKey: "AIzaSyDCPO9ixY5OLTW6TS4xB6o9WSOqyh1yNa8",
  authDomain: "karibu-f3349.firebaseapp.com",
  projectId: "karibu-f3349",
  storageBucket: "karibu-f3349.appspot.com",
  messagingSenderId: "145965077713",
  appId: "1:145965077713:web:0d56535d136c003c62a1f0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();

  const storeUserToken = gql`
    mutation upsertUserDeviceToken($token: String!) {
      upsertUserDevice(input: { token: $token, userDeviceId: $token }) {
        userDevice {
          user {
            userName
          }
        }
      }
    }
  `;

  if (permission === "granted") {
    getToken(messaging, {
      vapidKey:
        "BMicxJ2HO_6Jz4vZ8PyG6QWFsUMue_Uc7QeBsN57N0Ujsz6xEKuo579Mv_FF2pPRLQHP46Ua0PAxXwrpSDfNgoA",
    })
      .then((currentToken) => {
        if (currentToken) {
          client.mutate({
            mutation: storeUserToken,
            variables: {
              token: currentToken,
            },
          });
        } else {
          console.error(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.error("An error occurred while retrieving token. ", err);
      });
  } else {
    alert(
      "You won't be able to receive notifications for this application, Please enable notifications to do so thank you."
    );
  }
};
