import { cloneDeep } from "lodash";
import moment from "moment";
import _ from "lodash";
import { saveAs } from "file-saver";
import { Renderer } from "xlsx-renderer";
import { getApiBaseUrl } from "../config/env";
import { t } from "i18next";

const startOfDay = (date: Date): Date => {
  if (!date) date = new Date();
  return new Date(date.setHours(0, 0, 0, 0));
};

const endOfDay = (date: Date): Date => {
  if (!date) date = new Date();
  return new Date(date.setHours(23, 59, 59, 999));
};

const flattenObj = (ob: any, flattenArray = false): any => {
  // The object which contains the
  // final result
  let result: any = {};
  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        // Store temp in result
        result[i + "." + j] = temp[j];
      }
    } else if (Array.isArray(ob[i]) && flattenArray) {
      let _data = ob[i];
      for (let j = 0; j < _data.length; j++) {
        const temp = flattenObj(_data[j]);
        for (const k in temp) {
          // Store temp in result
          result[i + "[" + j + "]." + k] = temp[k];
        }
      }
    }
    // Else store ob[i] in result directly
    else {
      result[i] = ob[i];
    }
  }
  return result;
};

const PDF_LOGO = `${getApiBaseUrl().origin}/assets/images/hmaterials.png`;

const getPdfPrintParams = () => {
  return {
    PDF_PAGE_ORITENTATION: "landscape",
    PDF_WITH_HEADER_IMAGE: true,
    PDF_WITH_FOOTER_PAGE_COUNT: true,
    PDF_HEADER_HEIGHT: 25,
    PDF_ROW_HEIGHT: 15,
    PDF_ODD_BKG_COLOR: "#fcfcfc",
    PDF_EVEN_BKG_COLOR: "#fff",
    PDF_WITH_CELL_FORMATTING: true,
    PDF_WITH_COLUMNS_AS_LINKS: true,
    PDF_SELECTED_ROWS_ONLY: true,
    PDF_HEADER_COLOR: "#f8f8f8",
    PDF_INNER_BORDER_COLOR: "#dde2eb",
    PDF_OUTER_BORDER_COLOR: "#babfc7",
    PDF_LOGO,
  };
};

async function exportExcel(excelTemplate, viewModel, exportName) {
  let rendererBuffer = await new Renderer().renderFromArrayBuffer(
    excelTemplate,
    viewModel
  );
  let buffer = await rendererBuffer.xlsx.writeBuffer();

  saveAs(new Blob([buffer]), exportName);
}

const toTitleCase = (text: string) => {
  let result = text.replace(/([A-Z][a-z])/g, " $1");
  let ignoreTexts = ["of"];
  for (const text of ignoreTexts) {
    const regex = new RegExp(text, "gi");
    result = result.replace(regex, text);
  }
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

const checkIsDeliveryInclusive = (val: boolean) => val ? t("labels.delivered") : t("labels.ex-works");

function dateComparator(date1, date2) {
  var date1Number = _monthToNum(date1);
  var date2Number = _monthToNum(date2);

  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }

  return date1Number - date2Number;
}

function arraysContainSameStrings(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sorted1 = arr1.slice().sort();
  const sorted2 = arr2.slice().sort();

  for (let i = 0; i < sorted1.length; i++) {
    if (sorted1[i] !== sorted2[i]) {
      return false;
    }
  }

  return true;
}

function formatNumber(number) {
  const formattedNumber = Number(number).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: true,
  });

  return formattedNumber
    .replace(".", ",")
    .replace(/,/g, " ")
    .replace("-", "- ");
}

const timeFilterComparator = {
  FilterButtonType: ["apply", "clear", "reset", "cancel"],
  debounceMs: 500,
  suppressAndOrCondition: true,
  inRangeInclusive: true,
  inRangeFloatingFilterDateFormat: "HH:mm",
  textMatcher: (evt) => {
    const filterTextLowerCase = evt.filterText.toLowerCase();
    const valueLowerCase = moment(evt.value.toString())
      .format("HH:mm")
      .toLowerCase();
    switch (evt.filterOption) {
      case "contains":
        return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
      case "notContains":
        return valueLowerCase.indexOf(filterTextLowerCase) === -1;
      case "equals":
        return valueLowerCase === filterTextLowerCase;
      case "notEqual":
        return valueLowerCase !== filterTextLowerCase;
      case "startsWith":
        return valueLowerCase.indexOf(filterTextLowerCase) === 0;
      case "endsWith":
        var index = valueLowerCase.lastIndexOf(filterTextLowerCase);
        return (
          index >= 0 &&
          index === valueLowerCase.length - filterTextLowerCase.length
        );
      default:
        // should never happen
        console.warn("invalid filter type " + evt.filter);
        return false;
    }
  },
};

const formattedPTTime = (
  inputString: string,
  returns: "momentObject" | "string" = "string"
) => {
  const duration = moment.duration(inputString);
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes()) - hours * 60;
  const seconds = duration.seconds();
  const formattedTime = moment().hours(hours).minutes(minutes).seconds(seconds);

  return returns === "string"
    ? formattedTime.format("hh:mm:ss A")
    : formattedTime;
};

const filterComparator = {
  FilterButtonType: ["apply", "clear", "reset", "cancel"],
  debounceMs: 500,
  suppressAndOrCondition: true,
  inRangeInclusive: true,
  inRangeFloatingFilterDateFormat: "DD/MM/YYYY",
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    cellValue = moment(cellValue).format("DD/MM/YYYY");
    if (cellValue === null) {
      return 0;
    }
    var dateParts = cellValue.split("/");
    var year = Number(dateParts[2]);
    var month = Number(dateParts[1]) - 1;
    var day = Number(dateParts[0]);
    var cellDate = new Date(year, month, day);

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  },
};

// HELPER FOR DATE COMPARISON
function _monthToNum(date) {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }

  var yearNumber = date.substring(6, 10);
  var monthNumber = date.substring(3, 5);
  var dayNumber = date.substring(0, 2);

  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  // 29/08/2004 => 20040829
  return result;
}

// DATA FORMATTING
function dateFormatter(params) {
  var dateAsString = params.data.date;
  var dateParts = dateAsString.split("/");
  return `${dateParts[0]} - ${dateParts[1]} - ${dateParts[2]}`;
}

function paddObject(obj: any, image: any) {
  obj = cloneDeep(obj);
  const initObj = (_obj: any, image: any) => {
    if (!_obj) {
      if (typeof image === "object") {
        if (image?.forEach) {
          return [];
        } else {
          return {};
        }
      } else {
        return "";
      }
    }
    return _obj;
  };

  for (const key in image) {
    if (typeof image[key] === "object") {
      if (image[key]?.forEach && image[key]?.length > 0) {
        if (!obj[key]) obj[key] = [];

        let arrImage = image[key][0];
        obj[key].forEach((i: any, index: number) => {
          obj[key][index] = initObj(obj[key][index], arrImage);
          paddObject(obj[key][index], arrImage);
        });
      } else if (!image[key]?.forEach) {
        obj[key] = initObj(obj[key], image[key]);
        paddObject(obj[key], image[key]);
      }
    } else {
      if (obj && !obj[key]) {
        obj[key] = "";
      }
    }
  }
  return obj;
}

function wait(timeout: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("");
    }, timeout);
  });
}

const attachGridScrollEvent = (
  data: any[],
  loading: boolean,
  totalCount: number,
  loadMore: () => void,
  type: "grid" | "table" = "table",
  elementClass: string | undefined = undefined
) => {
  let scroller = document.querySelector<HTMLElement>(
    elementClass ??
    (type === "table"
      ? ".MuiTableContainer-root"
      : ".MuiDataGrid-virtualScroller")
  );
  if (scroller) {
    scroller.onscroll = (event) => {
      if (loading) return;
      let totalUserCount = totalCount ?? 0;
      let scrollHeight = (event.target as HTMLElement)?.scrollHeight;
      let clientHeight = (event.target as HTMLElement)?.clientHeight;
      let scrollTop = (event.target as HTMLElement)?.scrollTop;
      if (clientHeight + scrollTop + 20 >= scrollHeight) {
        if (Number(data?.length ?? 0) < totalUserCount) {
          loadMore();
        }
      }
    };
  }
};
const persistLastPOLocation = (pathName: string) => {
  localStorage.setItem("lastPillNavLocation", pathName);
};

const getLastPOLocation = () => {
  return localStorage.getItem("lastPillNavLocation");
};

const compareObjects = (obj1: object, obj2: object) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const convertToISO8601 = (date: Date = new Date()) => {
  return moment
    .duration({ hours: date?.getHours(), minutes: date?.getMinutes() })
    .toISOString();
};

const convertfromISO8601 = (date: string): Date => {
  return moment("2023-04-11T00:00:00Z").add(moment.duration(date)).toDate();
};

const objectDateComparator = (dateProperty: string) => {
  return (a, b) =>
    new Date(a?.[dateProperty] ?? "").getTime() -
    new Date(b?.[dateProperty] ?? "").getTime();
};

const convertFromPascal = (text: string) => {
  //convert from pascal to human readable and slice local from admin
  if (!text) return text;

  let temp = text
    .replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
      return " " + y;
    })
    .replace(/^_/, "")
    .trim();

  if (temp.toLowerCase() === "local administrator") {
    temp = "Administrator";
  }

  return temp;
};

function calculateTimeDifferenceFormatted(
  startDate: string,
  endDate: string
): string {
  const start = moment(startDate);
  const end = moment(endDate);

  const duration = moment.duration(end.diff(start));
  const hours = duration.hours();
  const minutes = duration.minutes();

  return `${hours}h ${minutes}m`;
}

function calculateTimeDifferenceInMinutes(
  datetime1: string,
  datetime2: string
): number {
  // Parse the input datetime strings
  const date1 = moment(datetime1);
  const date2 = moment(datetime2);

  // Calculate the difference in minutes
  const diffMinutes = date2.diff(date1, "minutes");

  return diffMinutes;
}

const calculateTimeDifference = (start: string, end: string): string => {
  const format = "HH:mm:ss";
  const startTime = moment(start, format);
  const endTime = moment(end, format);
  const duration = moment.duration(endTime.diff(startTime));
  const hours = Math.abs(Math.floor(duration.asHours()))
    .toString()
    .padStart(2, "0");
  const minutes = Math.abs(duration.minutes()).toString().padStart(2, "0");
  const seconds = Math.abs(duration.seconds()).toString().padStart(2, "0");
  if (isNaN(+hours) || isNaN(+minutes) || isNaN(+seconds)) {
    return "N/a";
  } else {
    return `${hours}:${minutes}:${seconds}`;
  }
};

const ellipsiceText = (data: string, capLength?: number) => {
  capLength = capLength ?? data.length;
  let lengthDifference = capLength - data.length;
  return (
    data.substring(0, capLength) +
    new Array(lengthDifference < 0 ? 0 : lengthDifference).fill(" ").join("") +
    (data[capLength] ? "..." : "   ")
  );
};

function getWeeksOfYear(yearString, startDay): { [key: string]: string[] } {
  const year = parseInt(yearString, 10);

  if (isNaN(year)) {
    throw new Error("Invalid year input");
  }

  if (startDay < 0 || startDay > 6) {
    throw new Error(
      "Invalid start day; it should be between 0 (Sunday) and 6 (Saturday)"
    );
  }

  const weeks: { [key: string]: string[] } = {};

  for (let month = 0; month < 12; month++) {
    for (let day = 1; day <= 31; day++) {
      const date = new Date(year, month, day);

      if (date.getFullYear() === year && !isNaN(date.getDay())) {
        const week = getWeekNumber(date, startDay);

        if (week.year != date.getFullYear()) continue;

        if (!weeks[week.week]) {
          weeks[week.week] = [];
        }

        if (!weeks[week.week].includes(date.toDateString()))
          weeks[week.week].push(date.toDateString());
      }
    }
  }

  for (let day = 1; day <= 7; day++) {
    const date = new Date(year + 1, 0, day);

    const week = getWeekNumber(date, startDay);

    if (week.year != year) continue;

    if (!weeks[week.week]) {
      weeks[week.week] = [];
    }

    weeks[week.week].push(date.toDateString());
  }

  return weeks;
}

function getWeekNumber(date, startDay): { year: number; week: number } {
  const january1st = getFirstDayOfWeekOfYear(date.getFullYear(), startDay);
  const january1stDay = (january1st.getDay() - startDay + 7) % 7; // Calculate the offset for January 1st

  const days = Math.floor((Number(date) - Number(january1st)) / 86400000); // 1 day = 86,400,000 milliseconds
  const weekNumber = Math.ceil((days + january1stDay) / 7);
  if (weekNumber == 0) {
    let prevYear = date.getFullYear() - 1;
    return {
      year: prevYear,
      week: getWeekNumber(new Date(prevYear, 11, 31), startDay).week,
    };
  } else {
    return { year: date.getFullYear(), week: weekNumber };
  }

  function getFirstDayOfWeekOfYear(year, dayOfWeek) {
    const january1st = new Date(year, 0, 1);
    const startDayOfWeek =
      (shiftDayByOne(dayOfWeek) - january1st.getDay() + 7) % 7;

    const firstDay = new Date(year, 0, 1 + startDayOfWeek);

    return firstDay;

    function shiftDayByOne(day: number) {
      if (day == 6) return 0;
      else return day + 1;
    }
  }
}

function getDateFromWeek(
  weekNumber: number,
  year: number,
  day: number,
  startDay
) {
  let weeksOfYear = getWeeksOfYear(year, startDay);

  let _dateContruct = contructDaysArrayWithStartDay(startDay);

  let dayIndex = _dateContruct.findIndex((i) => i == day);

  let dateString = weeksOfYear[weekNumber][dayIndex];

  return new Date(dateString);

  function contructDaysArrayWithStartDay(startDay: number) {
    let days: number[] = [];
    for (let i = startDay; i < startDay + 7; i++) {
      if (i > 6) {
        days.push(i % 7);
      } else {
        days.push(i);
      }
    }
    return days;
  }
}

const delayedReset = (action, time?: number) => {
  action(true);
  setTimeout(() => {
    action(false);
  }, time ?? 100);
};

const getDay = (date: Date) => {
  let day = date.getDay();
  if (day === 0) {
    day = 6; // Sunday, adjust to 6
  } else {
    day -= 1; // Shift other days by 1
  }
  return day;
};

function getDateFromWeekxx(weekNumber, year, day) {
  // Create a new Date object for January 1st of the given year
  const januaryFirst = new Date(year, 0, 1);

  // Calculate the number of days to the first day of the target week
  const daysToTargetWeek = (weekNumber - 1) * 7 + day;

  // Calculate the date of the first day of the target week
  const targetDate = new Date(
    januaryFirst.getTime() + daysToTargetWeek * 24 * 60 * 60 * 1000
  );

  // Calculate the day of the week for January 1st
  const januaryFirstDay = januaryFirst.getDay();

  // Calculate the day of the week for the target date
  const targetDay = (januaryFirstDay + day) % 7;

  // Calculate the number of days to add or subtract to get the correct day of the week
  const daysToAddOrSubtract = targetDay - targetDate.getDay();

  // Set the target date to the correct day of the week
  targetDate.setDate(targetDate.getDate() + daysToAddOrSubtract);

  return targetDate;
}

const convertCamelCaseToSpace = (str: string): string => {
  const result = _.map(str?.split(/(?=[A-Z])/), (part) =>
    part.toLowerCase()
  ).join(" ");
  return result.replace(/\b\w/g, (char) => char.toUpperCase());
};

function replaceReportDateFilterCrossSvgWithReload() {
  let counter = 0;
  let intervalId = setInterval(() => {
    counter++;
    let clearButton = document.querySelector(
      ".react-datetimerange-picker__clear-button"
    );
    if (counter > 5) clearInterval(intervalId);
    if (!clearButton) return;
    clearButton.innerHTML = `<svg height="12px" width="12px"  viewBox="0 0 32 32">
    <path d="M27.802 5.197c-2.925-3.194-7.13-5.197-11.803-5.197-8.837 0-16 7.163-16 16h3c0-7.18 5.82-13 13-13 3.844 0 7.298 1.669 9.678 4.322l-4.678 4.678h11v-11l-4.198 4.197z"></path>
    <path d="M29 16c0 7.18-5.82 13-13 13-3.844 0-7.298-1.669-9.678-4.322l4.678-4.678h-11v11l4.197-4.197c2.925 3.194 7.13 5.197 11.803 5.197 8.837 0 16-7.163 16-16h-3z"></path>
    </svg>`;
    clearInterval(intervalId);
  });
}

const getLoadingPercentage = (length?: number, totalCount?: number) => {
  if (!totalCount) return 100;
  return Math.round(
    (Number(length ?? 0) / Number(Boolean(totalCount) ? totalCount : 1)) * 100
  );
};

const daysObjects = [
  { name: "Monday", id: 0 },
  { name: "Tuesday", id: 1 },
  { name: "Wednesday", id: 2 },
  { name: "Thursday", id: 3 },
  { name: "Friday", id: 4 },
  { name: "Saturday", id: 5 },
  { name: "Sunday", id: 6 },
];

const transformFilterObjectToGqlVariables = (
  filterObjects: {},
  filterObjectMapper = {}
) => {
  let gqlVariablesObject = {};
  for (let filterKey in filterObjects) {
    let filterObject = filterObjects[filterKey];
    if (filterObjectMapper[filterKey]) {
      if (filterObject?.filterType == "text") {
        gqlVariablesObject[filterObjectMapper[filterKey]] =
          filterObject?.filter;
      } else if (filterObject?.filterType == "date") {
        gqlVariablesObject[filterObjectMapper[filterKey]] =
          filterObject?.dateFrom;
      }
    }
  }
  return gqlVariablesObject;
};

const enum LocalStorageKeys {
  ignoreGlobalAdmin = "ignoreGlobalAdmin",
  userADGroups = "userADGroups",
  currentUser = "currentUser",
}

const globalAdminGroup = "GRP Mizani YM GLB Users";

const formatDateTime = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    // second: "2-digit",
    hour12: true,
  });
};

enum OrderStatuses {
  Quotation,
  Dispatched,
  OrderConfirmed,
  PaymentConfirmed,
  Cancelled,
  Delivered,
  Completed,
  Pending,
  PaymentSubmitted,
  SOGenerated
}

const getPaymentModeTranslation = (description: string) => {
  switch (description) {
    case "Manual":
      return "label.manual";
    case "Available Balance":
      return "label.available-balance";
    case "Bank Deposit - Cash":
      return "label.bank-deposit-cash";
    case "Bank Deposit - Cheque":
      return "label.bank-deposit-cheque";
    case "Bank Transfer":
      return "label.bank-transfer";
    case "Credit Order":
      return "label.credit-order";
    case "Hualage Claim":
      return "label.hualage-claim";
    case "DFS Credit Order":
      return "label.dfs";
    case "E-Payment":
      return "label.e-payment";
    case "Bank Deposit":
      return "label.bank-deposit";
    case "Endorsed Draft":
      return "label.endorsed-draft";
    case "":
      return "N/A";
    case null:
      return "N/A";
    default:
      return description;
  }
};

const OrderStatusTranslation = (status: string) => {
  switch (status) {
    case "Quotation":
      return "salesvoucherstatusenum.quotation";
    case "Quotation Created":
      return "salesvoucherstatusenum.quotation";
    case "Waiting for Quotation":
      return "modules.content.orders.waiting-for-quotation";
    case "Dispatched":
      return "salesvoucherstatusenum.dispatched";
    case "Order Confirmed":
      return "salesvoucherstatusenum.orderconfirmed";
    case "Payment Confirmed":
      return "salesvoucherstatusenum.paymentconfirmed";
    case "Cancelled":
      return "salesvoucherstatusenum.cancelled";
    case "Delivered":
      return "salesvoucherstatusenum.delivered";
    case "Completed":
      return "salesvoucherstatusenum.completed";
    case "Pending":
      return "salesvoucherstatusenum.pending";
    case "Payment Submitted":
      return "salesvoucherstatusenum.paymentsubmitted";
    case "SOGenerated":
      return "salesvoucherstatusenum.sogenerated";
    default:
      return status;
  }
};


const summaryTitleTranslation = (title: string) => {
  switch (title) {
    case "Approved Payment":
      return "labels.approved-payment";
    case "Pending Payment":
      return "labels.pending-payment";
    case "Approved Orders":
      return "labels.approved-orders";
    case "Quotations":
      return "labels.quotations";
    case " Approved Payment":
      return "labels.approved-payment";
    case " Pending Payment":
      return "labels.pending-payment";
    case " Approved Orders":
      return "labels.approved-orders";
    case " Quotations":
      return "labels.quotations";
    default:
      return title;
  }
};

const getTextColor = (orderStatus: string) => {
  if (OrderStatuses[orderStatus] === OrderStatuses.OrderConfirmed || OrderStatuses[orderStatus] === OrderStatuses.SOGenerated) {
    return "text-[#A5AF37]";
  } else if (OrderStatuses[orderStatus] === Number(OrderStatuses.Completed)) {
    return "text-[#0BA047]";
  } else if (OrderStatuses[orderStatus] === Number(OrderStatuses.Delivered)) {
    return "text-[#00DA09]";
  } else if (
    OrderStatuses[orderStatus] === Number(OrderStatuses.PaymentConfirmed)
  ) {
    return "text-[#015FBF]";
  } else if (OrderStatuses[orderStatus] === Number(OrderStatuses.Cancelled)) {
    return "text-[#DA0901]";
  } else if (OrderStatuses[orderStatus] === Number(OrderStatuses.Dispatched)) {
    return "text-[#D49901]";
  } else if (OrderStatuses[orderStatus] === Number(OrderStatuses.Quotation) || orderStatus === "Waiting for Quotation") {
    return "text-[#FF8A1E]";
  }
};

export {
  getPaymentModeTranslation,
  OrderStatusTranslation,
  summaryTitleTranslation,
  LocalStorageKeys,
  globalAdminGroup,
  startOfDay,
  endOfDay,
  persistLastPOLocation,
  getLastPOLocation,
  flattenObj,
  dateComparator,
  objectDateComparator,
  dateFormatter,
  arraysContainSameStrings,
  timeFilterComparator,
  _monthToNum,
  toTitleCase,
  paddObject,
  wait,
  attachGridScrollEvent,
  filterComparator,
  formattedPTTime,
  formatNumber,
  compareObjects,
  convertToISO8601,
  convertfromISO8601,
  ellipsiceText,
  calculateTimeDifference,
  getWeeksOfYear,
  getWeekNumber,
  delayedReset,
  getDay,
  getDateFromWeek,
  replaceReportDateFilterCrossSvgWithReload,
  convertCamelCaseToSpace,
  getLoadingPercentage,
  calculateTimeDifferenceFormatted,
  calculateTimeDifferenceInMinutes,
  daysObjects,
  convertFromPascal,
  transformFilterObjectToGqlVariables,
  exportExcel,
  getPdfPrintParams,
  formatDateTime,
  getTextColor,
  OrderStatuses,
  checkIsDeliveryInclusive
};
