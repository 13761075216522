import { Navigate, Route, Routes } from "react-router-dom"
import { Logout } from "../pages/auth/components/Logout/Logout"
import { PrivateRoutes } from "./PrivateRoutes"
import AuthCallback from "../features/auth/AuthCallback"
import { Login } from "../pages/auth/components/Login/Login"
import App from "src/App"
import { useContext, useEffect, useState } from "react"
import AuthContext from "../contexts/auth-context"
import LanguageSelection from "../pages/auth/components/LanguageSelection"
import { useTranslation } from "react-i18next"
import Loader from "../pages/common/loader/loader"

export const AllRoutes = () => {
  const { i18n } = useTranslation();
  const { authContextData } = useContext(AuthContext);
  const [languageId,setLanguageId] = useState<string | null>(localStorage.getItem("languageId"));

  useEffect(()=>{
    if(authContextData?.currentUser)
    setLanguageId(localStorage.getItem("languageId"))
  },[i18n.language,authContextData]);

  console.log("authcontext", authContextData?.currentUser);
  console.log("languageId", languageId);
  return <Routes>
    <Route element={<App />}>

      {authContextData?.currentUser && languageId ? (
        <>
          <Route path="logout" element={<Logout />} />
          <Route path="/*" element={<PrivateRoutes />} />
          <Route index element={<Navigate to="/landing" />} />
        </>
      ) : (
        !authContextData?.currentUser && authContextData?.authLoaded ? (<>
          <Route path="*" element={<Login />} />
          <Route path="/auth" element={<AuthCallback />} />
        </>) : !authContextData?.currentUser && !authContextData?.authLoaded ?<>
        <Route path="*" element={<Loader loaderSize={20} />} />
        </>: <>
          <Route path="*" element={<LanguageSelection />} />
        </>
      )}
    </Route>
  </Routes>
}