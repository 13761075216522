import { getChallengeSecrets } from "../ChallengeSecrets";
import { AuthCodeFlow } from "../AuthCodeFlow";
import { useCallback } from "react";
import { getApiBaseUrl, getEnv } from "src/app/config/env";
import { useNavigate } from "react-router-dom";

const env = getEnv();

export const useAuth = () => {

  const navigate = useNavigate();

  const login = useCallback(async () => {
    try {
      const authFlow = new AuthCodeFlow(await getChallengeSecrets());
      await authFlow.startLoginProcess();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const register = useCallback(async () => {
    try {
      const authFlow = new AuthCodeFlow(await getChallengeSecrets());
      await authFlow.startRegisterProcess("create-account");
    } catch (error) {
      console.log(error);
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      const authFlow = new AuthCodeFlow(await getChallengeSecrets());
      await authFlow.startLogoutProcess();
      window.location.href = '/';
    } catch (error) {
      console.log(error);
    }
  }, []);

  const refreshAccessToken = useCallback(
    async (refresh_token) => {
      const tokenEnpoint = `${getApiBaseUrl().origin}/api/Identity/refresh_token`;
      try {
        const body = {
          refresh_token,
          grant_type: "refresh_token",
        };

        const response = await fetch(tokenEnpoint, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          return;
        }

        return response.json();
      } catch (error) {
        console.error("Token refresh error", error);
        logout();
      }
    },
    [logout]
  );

  return { login, register, refreshAccessToken, logout };
};
