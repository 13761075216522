import { SyntheticEvent, useState } from "react";
import { LocationTypeEnum } from "./LocationTypeEnum";
import { Autocomplete, TextField } from "@mui/material";

const locationTypeMap = {
  Plant: "P",
  Aggregates: "A",
  Depot: "D",
};

interface LocationTypeLookupSelectProps {
  onLocationTypeSelected?: (item: string) => void;
  initialValue?: string;
}
const LocationTypeLookupSelect = ({
  onLocationTypeSelected,
  initialValue,
}: LocationTypeLookupSelectProps) => {
  const [locationType, setLocationType] = useState<string>(
    LocationTypeEnum[initialValue ?? LocationTypeEnum.Plant]
  );

  const locationTypeChange = (value: SyntheticEvent<Element, Event>) => {
    if (!value.currentTarget.textContent) return;
    let _value = locationTypeMap[value.currentTarget.textContent];

    !!onLocationTypeSelected &&
      onLocationTypeSelected(_value as LocationTypeEnum);
    setLocationType(_value as LocationTypeEnum);
  };

  return (
    <Autocomplete
      onChange={locationTypeChange}
      value={locationType}
      disablePortal
      options={Object.keys(LocationTypeEnum)}
      sx={{ marginTop: "20px" }}
      renderInput={(params) => <TextField {...params} label="Type" />}
    />
  );
};

export default LocationTypeLookupSelect;
