import { useState, useContext, useEffect } from "react";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../../common/loader/loader";
import { useGetLanguagesQuery, useUpsertUserDefaultLanguagemutationMutation } from "src/generated/graphql";
import { LoadingButton } from "@mui/lab";
import AuthContext from "src/app/contexts/auth-context";

const LanguageSelection = () => {
    //const { language, saveLanguage } = useContext(LanguageSelectionContext);
    const authContext = useContext(AuthContext);
    const [selectedLanguage, setSelectedLanguage] = useState<number | undefined>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [upsertUserDefaultLanguagemutationMutation, { data: upsertUserDefaultLanguageData, loading: upsertingUserDefaultLanguage }] =
        useUpsertUserDefaultLanguagemutationMutation({
            fetchPolicy: "no-cache"
        });
    const {
        data: languagesData,
        loading: languagesLoading,
        error: languagesError,
    } = useGetLanguagesQuery();

    const completeLanguageSelection = async () => {
        const selectedLang = languagesData?.languages?.find(
            (val) => val.id === selectedLanguage
        );

        if (selectedLang && selectedLang.id !== undefined) {
            upsertUserDefaultLanguagemutationMutation({
                variables: {
                    langId: { languageId: selectedLang.id },
                },
            });
        }

        if (selectedLanguage !== undefined) {
            //await saveLanguage(selectedLanguage);
            if (selectedLang?.lang !== "Portuguese") {
                i18next.changeLanguage(selectedLang?.lang?.slice(0, 2).toLowerCase());
            } else {
                i18next.changeLanguage("pt");
            }


        }
    };
    console.log("LanaguageSelectionData", upsertUserDefaultLanguageData?.updateUserDefaultLanguage?.user);

    useEffect(() => {
        if (upsertUserDefaultLanguageData?.updateUserDefaultLanguage?.user && selectedLanguage) {
            localStorage.setItem("languageId", selectedLanguage.toString());
            authContext?.resetAuthContext?.(upsertUserDefaultLanguageData?.updateUserDefaultLanguage?.user?.email);
            navigate("/landing");
        }

    }, [upsertUserDefaultLanguageData?.updateUserDefaultLanguage?.user, authContext, navigate, selectedLanguage]);

    if (languagesLoading) return <Loader />;

    return (
        <div
            data-testid="LanguageSelectionScreen"
            className="min-w-full h-full relative bg-gradient-to-r from-sky-700 to-sky-900 pt-7 space-y-40 flex items-center justify-center overflow-auto"
        >
            <div className="flex items-center justify-center sm:w-[90%] md:w-[60%] lg:w-[50%]">
                <div className="self-center w-full sm:w-4/5 xl:w-2/5 mt-14 bg-white pt-6 mb-20 rounded-lg">
                    <div className="flex flex-row divide-x divide-slate-400/25 pt-10">
                        <div className="w-1/2 flex justify-center">
                            <img style={{ width: "clamp(100px, 30%, 300px)" }}
                                src="/assets/images/Karibu.svg"
                                alt="Karibu"
                            />
                        </div>
                        <div className="w-1/2 flex justify-center">
                            <img style={{ width: "clamp(100px, 30%, 300px)" }}
                                src="/assets/images/HeidelbergMaterials.svg"
                                alt="Heidelberg Materials"
                            />
                        </div>
                    </div>
                    <div className="border-t border-slate-200 mt-5 p-8 space-y-4">
                        <h1 className="text-[#00274D] text-4xl font-semibold text-center">
                            {t("labels.select-language")}
                        </h1>
                        <div className="overflow-y-auto h-[70%]">
                            {languagesError && (
                                <p className="mt-4 text-red-500">
                                    {t("messages.error.trouble-loading-languages")}
                                </p>
                            )}
                            {languagesData && (
                                <div className="mt-2">
                                    <div className="flex flex-col divide-y divide-slate-400/25 mt-3 ">
                                        {languagesData.languages.map((c) => (
                                            <button
                                                data-testid={`LanguageSelectionScreen.${c.id}`}
                                                key={c.id}
                                                onClick={() => setSelectedLanguage(c.id)}
                                                className={`py-3 grid grid-cols-1 place-content-center ${selectedLanguage === c.id ? "bg-[#D9E9F9]" : ""
                                                    }`}
                                            >
                                                <span
                                                    className={`text-[#00274D] text-xl ${selectedLanguage === c.id
                                                        ? "font-medium"
                                                        : "font-normal"
                                                        }`}
                                                >
                                                    {c.lang}
                                                </span>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <LoadingButton
                            data-testid="LanguageSelectionScreen.ContinueButton"
                            variant="contained"
                            loading={upsertingUserDefaultLanguage}
                            style={{
                                borderRadius: 5,
                                padding: "1rem",
                                width: "100%",
                                marginTop: "1rem",
                            }}
                            disabled={selectedLanguage === undefined || !!upsertUserDefaultLanguageData}
                            onClick={completeLanguageSelection}
                        >
                            {upsertUserDefaultLanguageData?.updateUserDefaultLanguage?.user ? t("labels.redirecting") : t("labels.continue")}
                        </LoadingButton>
                    </div>
                </div>
                {/* <MasterFooter /> */}
            </div>
        </div>
    );
};

export default LanguageSelection;
