import { useContext, useEffect, useState } from "react";
import { useGetPaginatedLocationsByCompanyLazyQuery } from "../../../../../generated/graphql";
import { AsyncSelect } from "../../async-select/AsyncSelect";
import { AsyncSelectData } from "../../async-select/AsyncSelectData";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../contexts/auth-context";

interface FormikFormSelectProps {
  fieldName: string;
  labelName?: string;
  intialId?: number;
  formik;
  type?: string;
  showLabel?: boolean;
}
 
const FormikLocationFormSelect = (props: FormikFormSelectProps) => {
  const userService = useContext(AuthContext);

  const [
    getLocationsByCompany,
    { data: locations, loading: loadingLocations },
  ] = useGetPaginatedLocationsByCompanyLazyQuery({
    variables: {
      name: "",
      code: "",
      companyId:Number(userService?.authContextData?.currentUser?.location?.companyId),
      countryId: Number(userService?.authContextData?.currentUser?.company?.countryId),
    },
  });

  const [initialLocation, setInitialLocation] = useState<AsyncSelectData>();

  useEffect(() => {
    getLocationsByCompany({
      variables: {
        name: "",
        code: "",
        companyId:Number(userService?.authContextData?.currentUser?.location?.companyId),
        countryId: Number(userService?.authContextData?.currentUser?.company?.countryId),
      },
    });
  }, [
    locations,
    userService?.authContextData?.currentUser?.location?.companyId,
    userService?.authContextData?.currentUser?.company.countryId,
    getLocationsByCompany,
  ]);

  const formikSetFieldValue = props.formik.setFieldValue;

  const { t } = useTranslation();

  useEffect(() => {
    if (locations?.locations) {
      let location = locations?.locations?.nodes?.find(
        (i) => i.id === props.intialId
      );
      if (location) {
        let locationSelectData = new AsyncSelectData({
          title: location?.name,
          value: location?.id,
        });
        setInitialLocation(locationSelectData);
        setTimeout(() => {
          formikSetFieldValue(props.fieldName, location?.id ?? 0);
        }, 200);
      } else {
        setInitialLocation(undefined);
        formikSetFieldValue(props.fieldName, 0);
      }
    }
  }, [
    setInitialLocation,
    locations,
    formikSetFieldValue,
    props.fieldName,
    props.intialId,
  ]);

  const selectedLocationChanged = (data: AsyncSelectData) => {
    formikSetFieldValue(props?.fieldName, data?.value);
  };

  return (
    <>
      <div className={`mt-3`}>
        <AsyncSelect
          label={props.showLabel ? props.labelName || t("modules.formik.location-label") : ""}
          onItemSelected={selectedLocationChanged}
          onOpen={() => {
            console.log("");
          }}
          defaultSelectOption={initialLocation}
          data={
            locations?.locations?.nodes?.map(
                  (i) =>
                    new AsyncSelectData({
                      title: i.name,
                      value: i.id,
                    })
                )
          }
          loadingData={loadingLocations}
          hasError={Boolean(
            props.formik.errors[props.fieldName] &&
              props.formik.touched[props.fieldName]
          )}
          className={`w-full`}
        />
      </div>
    </>
  );
};

export { FormikLocationFormSelect };
